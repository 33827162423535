import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Box, Button, Divider
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { css as customCss } from '../content/Style';
import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import ZModal from "./ZModal";
import ViewSave from "./ViewSave";
import Input from "./inputs/Input";
import { CheckBox, Padding } from "@mui/icons-material";

export default function ZFiles({
    title,
    headerList,
    onChangeBatch,
    onResults,
}) {
    const [message, setMessage] = React.useState('')
    const [head, setHead] = React.useState([])
    const [results, setResults] = React.useState([])
    const [uniqueColumn, setUniqueColumn] = React.useState("")
    const [hasHead, setHasHead] = React.useState(true)
    const [rows, setRows] = React.useState([])
    const [paint, setPaint] = React.useState({ 0: '#ef7d7d' })
    let selectedColumns = {}
    let partialResults = []
    onChangeBatch = onChangeBatch ? onChangeBatch : (batch) => { }
    const resetAll = () => {
        setPaint({})
        setMessage({})
        localStorage.startingPoint = 0
    }
    const readExcelFile = (e) => {
        try {
            resetAll()
            readXlsxFile(e.target.files[0]).then((rows) => {
                setRows(rows)
            })
        } catch (err) {
            setMessage("Please upload .xlsx file")
        }

    }
    const exportExcel = async () => {
        let exportHeader = []
        exportHeader.push({ value: "SL NO", fontWeight: 'bold' })
        head.map((value) => {
            exportHeader.push({ value: value, fontWeight: 'bold' })
        })
        exportHeader.push({ value: "Status", fontWeight: 'bold' })
        let hasRow = false
        let rows = [exportHeader]
        // contacts.map((contact, i) => {
        //   hasRow = true
        //   let cols = []
        //   cols.push({ column: 'SL NO.', type: String, value: String(i + 1) })
        //   contact.map((c, j) => {
        //     cols.push({ column: head[j], type: String, value: String(c) })
        //   })
        //   cols.push({ column: 'Status', type: String, value: status[contact[0]] ? "Success" : "Pending" })
        //   rows.push(cols)

        // })
        // console.log(rows)
        let dt = new Date().toLocaleString().replace(',', '')
        await writeXlsxFile(rows, {
            //columns, // (optional) column widths, etc.
            fileName: `message_report_${dt}.xlsx`
        })
    }
    const c = {
        border: '1px solid #d1d1d1',
        padding: '5px',
        maxWidth:100,
    }
    const t = {
        border: '1px solid #d1d1d1',
        padding: '5px',
        background: '#034291', color: 'white', fontSize: '12px',
        maxWidth:200,
    }
    const onBatchSave = (chunk_size = 10) => {
        if (!uniqueColumn || uniqueColumn == '') {
            setMessage({ type: "error", text: "Please select unique column" })
            return
        }
        if (Object.keys(selectedColumns).length == 0) {
            setMessage({ type: "error", text: "Please map your column" })
            return
        }

        setMessage({})
        let nRows = [...rows]
        if (hasHead) {
            nRows.shift()
        }
        let index = parseInt(localStorage.startingPoint ? localStorage.startingPoint : '0')
        var arrayLength = nRows.length;
        let to = () => {
            let chunk = nRows.slice(index, index + chunk_size);
            let out = chunk.map((row) => {
                let no = {}
                row.map((col, i) => {
                    if (selectedColumns[String(i)]) { no[selectedColumns[String(i)]] = col }
                })
                return no
            }
            )
            if (out.length > 0) onChangeBatch(uniqueColumn, out, (rows) => {
                partialResults = partialResults.concat(rows)

                let d = index
                while (d < index + chunk_size) {
                    paint[d++] = '#99bfe5'
                }

                setPaint({ ...paint })
                if (index >= nRows.length - 1) {
                    // clearInterval(to)
                    localStorage.startingPoint = 0
                    if (onResults) onResults(partialResults)
                } else {
                    localStorage.startingPoint = index
                    index += chunk_size
                    to()
                }
            })

        }
        setTimeout(to, 100)
        // let to = setInterval(() => {
        //     let chunk = nRows.slice(index, index + chunk_size);
        //     let out = chunk.map((row) => {
        //         let no = {}
        //         row.map((col, i) => {
        //             if (selectedColumns[String(i)]) { no[selectedColumns[String(i)]] = col }
        //         })
        //         return no
        //     }
        //     )
        //     if (out.length > 0) onChangeBatch(out, (rows) => {
        //         partialResults = partialResults.concat(rows)
        //     })

        //     let d = index
        //     while (d < index + chunk_size) {
        //         paint[d++] = '#99bfe5'
        //     }

        //     setPaint({ ...paint })
        //     if (index >= nRows.length - 1) {
        //         clearInterval(to)
        //         localStorage.startingPoint = 0
        //         if (onResults) onResults(partialResults)
        //     } else {
        //         localStorage.startingPoint = index
        //         index += chunk_size
        //     }
        // }, 500)

    }
    const onMapping = (e, colIndex) => {
        if (e.target.value === "") {
            delete selectedColumns[colIndex]
        } else {
            selectedColumns[colIndex] = e.target.value
        }
    }
    return (
        <>
            <h3>{title}</h3>
            <Box display="flex"
                justifyContent="center"
                alignItems="center" sx={{ color: customCss.Colors[4].color }}>

                <Typography><input style={{ display: 'none' }} id="fu" type="file" onChange={(e) => {
                    readExcelFile(e)
                }} /><label htmlFor="fu">Choose File</label></Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />

                <Typography onClick={async () => {
                    await exportExcel()
                }}>Download</Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />

                <Typography> <Button onClick={(e) => {
                    setHead(rows.shift())
                }} >Remove First</Button></Typography>
                <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />

                <Typography> <Button onClick={(e) => {
                    onBatchSave()
                }} >Upload</Button></Typography>
            </Box>
            <Divider />
            {rows?.length > 0 && <Box display="flex"
                alignItems="center" sx={{ color: customCss.Colors[4].color, my: 1 }}>
                <Input type="select"
                    label="Unique ID"
                    options={headerList}
                    val="key" text={"label"}
                    value={uniqueColumn}
                    style={{ marginBottom: 0 }}
                    onChange={(e) => setUniqueColumn(e.target.value)} />
                {message && <Box sx={{ textAlign: 'center', width: '80%' }} className={message.type}>{message.text}</Box>}
            </Box>}
            <Box sx={{ maxHeight: '80vh', overflow: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%', borderRadius: 4 }}>
                    <thead>
                        <tr>
                            {rows.length > 0 && rows[0].map((col, i) => <th key={i} style={t}>
                                <select onChange={(e) => { onMapping(e, i) }}>
                                    <option value="">Map the column</option>
                                    {headerList?.map((col, j) => typeof col == 'string' ? <option key={j}>{col}</option> : <option key={j} value={col?.key}>{col?.label}</option>
                                    )}
                                </select>
                                {col}
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row, i) => <tr key={i} style={{ ...c }} >
                            {row.map((col, j) => i == 0 ? null : <td key={j} style={{ ...c, color: paint[i] }}>{col}</td>)}
                        </tr>)}
                    </tbody>
                </table>
            </Box>
        </>
    );
}
