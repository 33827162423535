import * as React from "react";
import { Grid, Avatar, Typography, Box ,Divider} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XForm, ZButton, Input } from "../../libs/Libs";
import { REQUESTS } from "../../services/types";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";
import { css as customCss } from '../../content/Style'

const ContactUs = ({ title, phone, email, address }) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    return (
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ 'height': '80vh' }}
        >
            <Grid item align="center" alignItems="center" xs={12} md={3} xl={3}>
                <Box sx={{ ...customCss.Form }}>
                    <Typography component={'h1'}>{title}</Typography>
                    <Typography component={'h4'}>{phone}</Typography>
                    <Typography component={'h4'}>{email}</Typography>
                    <Box display="flex"
                        justifyContent="center"
                        alignItems="center" sx={{ color: customCss.Colors[4].color,fontSize:14 }}>
                        <Typography onClick={() => {
                            navigate("/")
                        }}>Home</Typography>
                        <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                        <Typography onClick={() => {
                            navigate("/contact_us/")
                        }}>Contact</Typography>
                        <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                        <Typography onClick={() => {
                            navigate("/packages/")
                        }}>Get a quote</Typography>
                        <Divider orientation="vertical" sx={{ height: 14, marginLeft: 1, marginRight: 1 }} />
                        <Typography onClick={() => {
                            navigate("/login/")
                        }}>Login</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};
export default ContactUs;
