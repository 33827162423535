import * as React from "react";
import { CImg, Input, XForm, K, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid } from "@mui/material";
import ZModal from "../../libs/ZModal";
import Badge from '@mui/material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import { css as customCss } from '../../content/Style'

export default function Group(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [permission, setPermission] = React.useState({});
    let groupState = useSelector((state) => state.group.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.GROUP.GET.ACTION, payload: 0
        })
    }, [])

    const onSubmit = (e, data) => {
        let action = data.id ? REQUESTS.GROUP.PATCH.ACTION : REQUESTS.GROUP.POST.ACTION
        dispatch({
            type: action, payload: data, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    const onDelete = (data) => {
        let action = REQUESTS.GROUP.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }

    const onAssign = (data) => {
        console.log("GroupAssign", data)
        let action = REQUESTS.GROUP.PATCH.ACTION
        dispatch({
            type: action, payload: data, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }

    return <>
        <ZModal showModal={showModal} title={"Permission"} onAction={() => { setShowModal(false) }}>
            <div>Role : {permission?.name}</div>
            <Input />
            {permission._permission && Object.keys(permission._permission)?.map((model) =>
                <div style={{ border: '1px solid gray', padding: 4, marginBottom: 2, borderRadius: 4 }}>
                    <div style={{ textTransform: 'capitalize' }}>{model}</div>
                    <Badge>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            {permission._permission[model]?.map((per) => <>
                                {per.name.indexOf("add") > -1 && <span
                                    onClick={() => { onAssign({ id: 0, name: permission?.name, permissions: [per.codename], action: per.is_assigned }) }}>
                                    Add <input type="checkbox" defaultChecked={per.is_assigned} />
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                </span>}
                                {per.name.indexOf("change") > -1 && <span
                                    onClick={() => { onAssign({ id: 0, name: permission?.name, permissions: [per.codename], action: per.is_assigned }) }}>Change <input type="checkbox" defaultChecked={per.is_assigned} />
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                </span>}
                                {per.name.indexOf("view") > -1 && <span
                                    onClick={() => { onAssign({ id: 0, name: permission?.name, permissions: [per.codename], action: per.is_assigned }) }}>View <input type="checkbox" defaultChecked={per.is_assigned} />
                                    <Divider orientation="vertical" variant="middle" flexItem /></span>}
                                {per.name.indexOf("delete") > -1 && <span
                                    onClick={() => { onAssign({ id: 0, name: permission?.name, permissions: [per.codename], action: per.is_assigned }) }}>Delete <input type="checkbox" defaultChecked={per.is_assigned} />
                                    <Divider orientation="vertical" variant="middle" flexItem /></span>}
                            </>)}
                        </Box>
                    </Badge>
                </div>)
            }
            {!permission._permission && <span>No data avialable</span>}
        </ZModal>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={groupState.results}
                    onSubmit={onSubmit}
                    // showKeys
                    headerList={["image","name"]}
                    hideMore={["id", "name"]}
                    onDelete={onDelete}
                    title={"Create New Role"}
                    moreIcon={<span style={customCss.Btn.Warn}><KeyIcon sx={{fontSize:customCss.Btn.Warn.fontSize}}/></span>}
                    onMore={(data) => {
                        setPermission(data)
                        setShowModal(true)
                    }}
                >
                    <p> </p>
                    <p style={{ fontSize: 10 }}>Creating and managing users with the user roles .</p>
                    <Input label={"Enter Role"} name={"name"} />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={8}>
                <div style={{...customCss?.Content?.small, position: "relative", height: '100%',paddingTop:'15%',textAlign:'center',width:'100%' }}>
                    Group and permission
                    <p>site has restricted areas  based on permissions,</p>
                    <p>It will need to allow access to different users based on a specification.</p>
                </div>
            </Grid>
        </Grid>
    </>
}