import * as React from "react";
import { Input, ViewSave } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Divider, Grid } from "@mui/material";
import ZModal from "../../libs/ZModal";
import Badge from '@mui/material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import { css as customCss } from '../../content/Style'
import HBox from "../extra/HBox";

export default function UserPermission(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [group, setGroup] = React.useState({});
    let userGroupState = useSelector((state) => state.usergroup.data)
    let userState = useSelector((state) => state.user.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.USERGROUP.GET.ACTION, payload: 0 })
        console.log("USER GROUP", userGroupState)
    }, [])

    const onSubmit = (e, data) => {
        let action = data.id ? REQUESTS.GROUP.PATCH.ACTION : REQUESTS.GROUP.POST.ACTION
        dispatch({
            type: action, payload: data, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    const onDelete = (data) => {
        let action = REQUESTS.GROUP.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }

    const onAssign = (data) => {
        console.log("GroupAssign", data)
        let action = REQUESTS.USERGROUP.PATCH.ACTION
        dispatch({
            type: action, payload: data, callback: () => {
                dispatch({ type: REQUESTS.USERGROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    const onClickPagination = (pageNumber) => {
        let action = REQUESTS.USERGROUP.GET.ACTION
        dispatch({ type: action, payload: `user/group/?page=${pageNumber}` })
    }

    return <>
        <ZModal showModal={showModal} title={"Assign Role to User"} onAction={() => { setShowModal(false) }}>
            <div>User : {group?.first_name} {group?.last_name}</div>
            <Input />

            <Badge>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    {group?.group && group?.group?.map((gr) => <>
                        <span
                            onClick={() => { onAssign({ id: group.id, groups: [gr.name], action: gr.is_assigned }) }}>
                            {gr.name}<input type="checkbox" defaultChecked={gr.is_assigned} />
                            <Divider orientation="vertical" variant="middle" flexItem />
                        </span>
                    </>)}
                </Box>
            </Badge>
            {!group?.group && <span>No data avialable</span>}
        </ZModal>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave dataList={userGroupState.results}
                    onSubmit={onSubmit}
                    headerList={["avatar","first_name"]}
                    hideMore={["id"]}
                    onDelete={onDelete}
                    icon="avatar"
                    title={"Assign User To Role"}
                    moreIcon={<span  style={{...customCss.Btn.Warn}}><KeyIcon sx={{fontSize:customCss.Btn.Warn.fontSize}}/></span>}
                    onMore={(data) => {
                        setGroup(data)
                        setShowModal(true)
                    }}
                    hideDelBtn
                    hideEditBtn
                    pagination={true}
                    totalPaginationCount={userGroupState.count}
                    onClickPagination={onClickPagination}
                    renderCol={(data, key) => {
                        if (key !== 'first_name') return typeof data[key] == 'string' ? <Box style={{ width: '100%' }}>
                            {data[key]}
                        </Box> : null
                        return <HBox data={data} key1={key} key2={"type"} key3={"whatsapp_number"}/>
                    }}
                >
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={8} justifyContent="center"
                    alignItems="center" sx={{ 'height': '80vh' }}>
                <Box 
                    align="center" alignItems="center">
                    <div style={{ ...customCss?.Content?.small, position: "relative", textAlign: 'center', width: '100%' }}>
                        Group and permission
                        <p>site has restricted areas  based on permissions,</p>
                        <p>It will need to allow access to different users based on a specification.</p>
                    </div>
                </Box>

            </Grid>
        </Grid>
    </>
}